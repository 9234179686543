/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, {
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third-party modules
import {
  Progress,
  SpeedWork,
  Work as WorkV3,
  WorkProps as WorkV3Props
} from "@onehq/anton";
import { useLazyQuery } from "@apollo/client";
import Userfront from "@userfront/core";

// app modules
import {
  WorkItemEdge,
  GetWorkItemsDocument,
  GetWorkProgressDocument,
  GetWorkListDocument,
  useCustomCurrentUserQuery,
  SendTextDocument
} from "../../generated/graphql";
import {
  QueryParams,
  WorkData,
  WorkItem,
  WorkItemType,
  WorkProps
} from "./types";
import {
  formatItems,
  formatWorkList,
  hasNextItemInPage,
  hasPreviousItemInPage,
  sortByName
} from "./utils";
import { WindowSizeContext } from "../../routes";
import {
  CLIENTS_PATH,
  LISTS_PATH,
  PHONES_PATH,
  PROJECTS_PATH,
  TEAMS_PATH,
  TEXTS_PATH,
  USERS_PATH,
  WORK_PATH
} from "../../constants";
import { baseUrl } from "../../pages/stepper/message";

// texts are beign send in batches now
const BATCH_SIZE = 100;

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-floating-promises
export const accessToken = Userfront.tokens.accessToken;
type Method = WorkV3Props["communicationMethod"];

// these 3 values will change really fast, so we will remove them from status
interface SendingStatus {
  currItemIdx?: number;
  currentItem?: WorkItem;
  batch: string[];
}

const Work = ({ workDataList: initialWorkList }: WorkProps) => {
  const { data: currentUser } = useCustomCurrentUserQuery();
  const user = useMemo(() => currentUser?.currentUser, [currentUser]);

  const hasWork = useMemo(() => user?.work && user.work.length > 0, [user]);
  // set workDataList from props if its defined, if its not, set from currentUser
  const workDataList = useMemo(() => {
    return initialWorkList
      ? initialWorkList
      : hasWork
      ? user
        ? formatWorkList(user.work)
        : []
      : [];
  }, [initialWorkList, user, hasWork]);

  const [workList, setWorkList] = useState<WorkData[]>([]);
  const [currentWork, setCurrentWork] = useState<WorkData>();
  const [message, setMessage] = useState("");
  const [showSpeedMode, setShowSpeedMode] = useState(false);
  const [communication, setCommunication] = useState<Method>();
  // if an user is actively sending messages at high speed
  const [loading, setLoading] = useState(false);

  // sendstatus will persist and only it's values will change
  // note that sendstatus and batch should never change their references (using =)
  // so that we can always change their inner attributes
  // and accessing them with their updated values
  const sendStatus = useRef<SendingStatus>({ batch: [] });
  // timer used to check if user is clicking "send" too fast
  const batchTimer = useRef<NodeJS.Timeout>();
  const { currItemIdx, batch } = sendStatus.current;
  if (currItemIdx !== undefined) {
    sendStatus.current.currentItem = currentWork?.items[currItemIdx];
  }
  const currentItem = sendStatus.current.currentItem;

  const windowObserver = useContext(WindowSizeContext);
  const navigateTo = useNavigate();
  const location = useLocation();

  const [sendTexts] = useLazyQuery(SendTextDocument);
  const [getWorkItems] = useLazyQuery(GetWorkItemsDocument);
  const [getProgress] = useLazyQuery(GetWorkProgressDocument);
  const [getWorkList] = useLazyQuery(GetWorkListDocument);

  useEffect(() => {
    // this check is here in order to only load data the first time we load the user's work
    // this way we can ignore all the updates in the work.currentItemId (triggers refetch of data)
    if (!currentWork && workDataList.length > 0) {
      sortByName(workDataList);
      setWorkList(workDataList);
      setCurrentWork({ ...workDataList[0] });
    }
  }, [workDataList]);

  useEffect(() => {
    if (currentWork && !currentWork.paginationInfo.loading) {
      // when loading the work, it's list of pages is empty at the start
      if (currentWork.status === "ready") {
        loadItemsPage(currentWork, "current");
      }
      setMessage(currentWork.generalMessage || "");

      const index = sendStatus.current.currItemIdx;
      if (index === undefined && currentWork.items.length > 0) {
        const lastIndex = currentWork.items.length - 1;
        const newIdex = currentWork.status === "loaded-next" ? 0 : lastIndex;
        sendStatus.current.currItemIdx = newIdex;
        sendStatus.current.currentItem = currentWork.items[newIdex];
      }

      const currWorkIndex = workList.findIndex(w => w.id === currentWork.id);
      workList[currWorkIndex] = currentWork;
      setWorkList([...workList]);
    }
  }, [currentWork]);

  // when loading is set to false, it means that we are not batching anymore
  // and is time to send the message (force=true)
  useEffect(() => {
    if (!loading && currentWork) {
      currentWork.progress.processed += batch.length;
      setCurrentWork(work => (work ? { ...work } : undefined));
      onSend(true);
    }
  }, [loading]);

  const onNext = () => {
    const currItemIdx = sendStatus.current.currItemIdx;
    if (currentWork && currItemIdx !== undefined) {
      if (hasNextItemInPage(currentWork, currItemIdx)) {
        setCurrentItemInPage(currItemIdx + 1);
      } else {
        loadItemsPage(currentWork, "after");
      }
    }
  };

  const onBack = () => {
    const currItemIdx = sendStatus.current.currItemIdx;
    if (currentWork && currItemIdx !== undefined) {
      if (hasPreviousItemInPage(currItemIdx)) {
        setCurrentItemInPage(currItemIdx - 1);
      } else {
        loadItemsPage(currentWork, "before");
      }
    }
  };

  const onWorkClick = (work: WorkData) => {
    setCurrentWork(work);
  };

  const onWorkClose = (work: WorkData) => {
    const workIdx = workList.findIndex(w => w.id === work.id);
    workList.splice(workIdx, 1);
    setWorkList([...workList]);
  };

  // every time we check the list of work, we update the work list
  const onWorkListClick = () => {
    const queryParams: QueryParams = { fetchPolicy: "no-cache" };
    getWorkList(queryParams)
      .then(response => {
        const updWork: WorkData[] = response?.data?.workList?.nodes || [];

        // if we already have work loaded, we keep them
        // that is because we may have some data that aren't in the new list
        // e.g. the current item in the current page of the work
        const newWorkList = updWork.map(w => {
          const existingWork = workList.find(
            existingWork => existingWork.id === w.id
          );
          return existingWork || w;
        });

        sortByName(newWorkList);
        setWorkList(newWorkList);

        // if the currentWork is gone, just assign the first one in the list
        const current = newWorkList.find(w => w.id === currentWork?.id);
        if (!current && newWorkList.length > 0) {
          setCurrentWork({ ...newWorkList[0] });
        } else if (newWorkList.length === 0) {
          setCurrentWork(undefined);
        }
      })
      .catch((err: any) => console.error(err));
  };

  const onTextChange = (value: string) => {
    setMessage(value);
  };

  const setCurrentItemInPage = (newCurrentItemIndex: number) => {
    if (currentWork) {
      sendStatus.current.currItemIdx = newCurrentItemIndex;
      sendStatus.current.currentItem = currentWork.items[newCurrentItemIndex];

      if (!showSpeedMode) {
        navigateTo(`/texts/${currentItem?.text.id}/overview`, {
          replace: true
        });
      }
    }
  };

  const onSend = (force?: boolean) => {
    // get current values
    const batch = sendStatus.current.batch;
    const currItemIdx = sendStatus.current.currItemIdx;
    const currentItem = sendStatus.current.currentItem;
    if (!currentWork || !currentItem || currItemIdx === undefined) return;

    // when force is false, we are not adding texts anymore. we just send
    if (!force) batch.push(currentItem.text.id);

    const maxSize = batch.length === BATCH_SIZE;
    const endOfPage = currItemIdx === currentWork.items.length - 1;
    if (batch.length && (force || maxSize || endOfPage)) {
      void sendTexts({
        fetchPolicy: "no-cache",
        variables: {
          projectId: currentWork.projectId,
          textIds: [...batch],
          message
        }
      });
      if (loading) {
        currentWork.progress.processed += batch.length;
        setCurrentWork(work => (work ? { ...work } : undefined));
      }
      batch.splice(0, BATCH_SIZE);
    }

    if (!force) onNext();
  };
  // down here we handle events of the "send" button
  const onMouseDown = () => {
    if (batchTimer.current) clearTimeout(batchTimer.current);

    batchTimer.current = setTimeout(() => {
      batchTimer.current = undefined;
      setLoading(() => false);
    }, 500);

    if (!loading) setLoading(() => true);
    onSend();
  };
  const onEnterDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      onMouseDown();
    }
  };

  // "before" and "after": they are called when the user changes the current item (and page)
  // "current": it's called only when loading a project. dont change anything, just load
  const loadItemsPage = (
    work: WorkData,
    whichPage: "before" | "current" | "after"
  ) => {
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: { workId: work.id }
    };

    sendStatus.current.currItemIdx = undefined;
    setCurrentWork({
      ...work,
      paginationInfo: { ...work.paginationInfo, loading: true }
    });

    getWorkItems(queryParams)
      .then(response => {
        const { cursors, pageInfo, edges, totalCount } =
          response.data.workItems;

        const workItems = edges?.map(
          (e: WorkItemEdge) => e.node
        ) as WorkItemType[];
        work.items = formatItems(workItems || [], work.generalMessage);

        // update/refresh the pagination data
        work.paginationInfo = {
          loading: false,
          pages: cursors || [],
          page: pageInfo.page,
          pageIndex: pageInfo.pageIndex,
          hasNext: pageInfo.hasNextPage,
          hasPrevious: pageInfo.hasPreviousPage,
          total: totalCount
        };
        work.status = `loaded-${whichPage === "before" ? "previous" : "next"}`;

        // this is multiclick specific.
        // if we request a next page but there are no more items,
        // the response keep saying that there is still a previous page.
        // we don't want to allow going anywhere when there are no more pages.
        const noMorePages = workItems.length === 0;

        if (noMorePages) {
          sendStatus.current.currItemIdx = undefined;
          sendStatus.current.currentItem = undefined;
          setCurrentWork(old => {
            return {
              ...old,
              ...work,
              progress: {
                processed: work.progress.total,
                total: work.progress.total,
                errors: work.progress.errors
              }
            };
          });
        } else if (whichPage === "current") {
          // "current" is used only when loading the initial data
          const project = workItems[0].text?.project;
          const { id = "", mediaUrl, message } = project || {};
          if (!work.generalMessage) work.generalMessage = message;
          setCurrentWork(old => ({
            ...old,
            ...work,
            projectId: id,
            mediaUrl: mediaUrl || ""
          }));
          if (work.items[0]?.contact?.phone) setCommunication("message");
        } else {
          setCurrentWork(old => ({ ...old, ...work }));
        }
        updateWorkProgress();
      })
      .catch((err: any) => console.error(err));
  };

  const updateWorkProgress = () => {
    if (!currentWork) return;
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: {
        workId: currentWork.id
      }
    };

    getProgress(queryParams)
      .then(response => {
        const progress = response.data.getWorkProgress.progress as Progress;
        setCurrentWork(work => (work ? { ...work, progress } : undefined));
      })
      .catch((err: any) => console.error(err));
  };

  const switchViews = () => {
    if (!showSpeedMode) {
      setShowSpeedMode(true);
    } else {
      setShowSpeedMode(false);
      const currentItem = sendStatus.current.currentItem;
      if (currentItem) {
        navigateTo(`/texts/${currentItem.text.id}/overview`, { replace: true });
      }
    }
  };

  // change the communication type.
  // you can't switch between communication methods directly,
  // you have to hide the current method and then choose another one.
  const toggleCommunication = (type: Method) => {
    setCommunication(communication ? undefined : type);
    // reset message when changing communication methods
    // messaging uses plain text and call & mail uses html
    setMessage(message || "");
  };

  const mediaUrl = currentWork?.mediaUrl && baseUrl + currentWork.mediaUrl;

  if (!currentWork) return <></>;
  // if pathname is none of these, dont show work
  if (
    ![
      LISTS_PATH,
      TEXTS_PATH,
      PROJECTS_PATH,
      USERS_PATH,
      CLIENTS_PATH,
      PHONES_PATH,
      TEAMS_PATH,
      WORK_PATH
    ].find(path => location.pathname.includes(path))
  )
    return <></>;
  if (showSpeedMode) {
    return (
      <SpeedWork
        work={{ ...currentWork, currentItem }}
        hasNext
        totalItems={currentWork.paginationInfo.total}
        method="message"
        editorText={message}
        onEditorChange={onTextChange}
        placeholders={[]}
        onMouseDown={onMouseDown}
        loading={loading}
        onKeyDown={onEnterDown}
        onClose={switchViews}
        imageUrl={mediaUrl}
        mobile={windowObserver.isMobile}
      />
    );
  } else {
    return (
      <WorkV3
        workList={workList}
        currentWork={{ ...currentWork, currentItem }}
        communicationMethod={communication}
        communicationText={message}
        onTextChange={onTextChange}
        placeholders={[]}
        onSave={onSend}
        onWorkClick={onWorkClick}
        onWorkClose={onWorkClose}
        onWorkListClick={onWorkListClick}
        onBack={onBack}
        onNext={onNext}
        hasPrevious={hasPreviousItemInPage(currItemIdx)}
        hasNext
        onCallToggle={() => toggleCommunication("call")}
        onMuteToggle={() => console.log("onMuteToggle")}
        onSilenceToggle={() => console.log("onSilenceToggle")}
        onMessageToggle={() => toggleCommunication("message")}
        onMailToggle={() => toggleCommunication("email")}
        onSkip={onNext}
        onSpeedToggle={switchViews}
        disabledCall
        disabledMail
        imageUrl={mediaUrl}
        mobile={windowObserver.isMobile}
      />
    );
  }
};

export default Work;
